








































import api from "@/api";
import { Component, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";

@Component({
  name: "CommoditySalesRecordDetails",
  components: {
    PagedTableView,
  },
})
export default class CommoditySalesRecordDetails extends Vue {
  queryForm = {
    productSaleMainId: 0,
    productName: undefined,
    productNumber: undefined,
  };

  created() {
    if (this.$route.params.productSaleMainId) {
      this.queryForm.productSaleMainId = parseInt(
        this.$route.params.productSaleMainId
      );
    }
  }

  fetchData(params: any) {
    return api.supplierProductSalesDetail.getAll(params);
  }
}
